<template>
  <vs-row v-if="podeExibirDadosComplementares" class="pt-0">
    <vs-col class="w-full md:w-1/4 pt-4">
      <vs-input
        :disabled="!perfilHelpers.isInsurance()"
        label="Código corretor mapfre"
        v-model="broker.CodigoCorretor"
        size="large"
        maxlength="10"
        @input="validarNumeros($event, 'broker.CodigoCorretor')"
      />
    </vs-col>

    <vs-col class="w-full md:w-1/4 pt-4">
      <vs-input
        :disabled="!perfilHelpers.isInsurance()"
        label="Código sucursal"
        v-model="broker.CodigoSurcursal"
        size="large"
        maxlength="10"
        @input="validarNumeros($event, 'broker.CodigoSurcursal')"
      />
    </vs-col>

    <vs-col class="w-full md:w-1/4 pt-4">
      <vs-input
        :disabled="!perfilHelpers.isInsurance()"
        label="Código contrato"
        v-model="broker.CodigoContrato"
        size="large"
        maxlength="10"
        @input="validarNumeros($event, 'broker.CodigoContrato')"
      />
    </vs-col>

    <vs-col class="w-full md:w-1/4 pt-4">
      <ValidationProvider
        name="canal"
        rules="required"
        v-slot="{ errors }"
        tag="vs-col"
      >
        <vs-select
          :disabled="!perfilHelpers.isInsurance()"
          class="large w-full border-select"
          label="Canal"
          :value="broker.TipoCanalId"
          v-model="broker.TipoCanalId"
          :reduce="tipoCanal => tipoCanal.Id"
        >
          <vs-select-item
            :key="index"
            :value="tipoDeCanal.Id"
            :text="tipoDeCanal.Descricao"
            v-for="(tipoDeCanal, index) in tiposDeCanais"
          />
        </vs-select>

        <span class="text-danger text-sm" v-show="errors[0]">
          {{ errors[0] }}
        </span>
      </ValidationProvider>
    </vs-col>
  </vs-row>
</template>

<script>
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  props: {
    broker: {
      type: Object,
      required: true
    },
    permissoesCorretorMapfre: {
      type: Array,
      required: true
    },
    tiposDeCanais: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      perfilHelpers: PerfilHelpers
    };
  },
  computed: {
    podeExibirDadosComplementares() {
      return this.permissoesCorretorMapfre.includes("ExibirDadosComplementares");
    }
  },
  methods: {
    validarNumeros(evento, caminho) {
      const valoresNumericos = evento.replace(/\D/g, "");

      let target = this;
      const parts = caminho.split(".");
      for (let i = 0; i < parts.length - 1; i++) {
        target = target[parts[i]];
      }
      target[parts[parts.length - 1]] = valoresNumericos;
    }
  }
};
</script>

<style scoped lang="scss"></style>

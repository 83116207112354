<template>
  <div id="dadosCadastrais" class="tabContent active">
    <vs-row>
      <vs-col class="w-full md:w-1/2 lg:w-1/3">
        <vs-input
          label="CNPJ"
          disabled="true"
          :value="corretor.BrokerDocument || corretor.CpfCnpj | maskCnpj"
          size="large"
          v-mask="['##.###.###/####-##']"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/2 lg:w-2/3">
        <vs-input
          label="Razão Social"
          v-model="brokerName"
          :disabled="!podeAlterar"
          size="large"
        />
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col class="w-full md:w-1/3">
        <vs-input
          @blur="updateInscricaoMunicipal()"
          label="Inscrição Municipal"
          :disabled="!podeAlterar"
          v-model="corretor.InscricaoMunicipal"
          size="large"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/3">
        <vs-input
          label="Comissão"
          :disabled="!perfilHelpers.isInsurance()"
          v-model="broker.ComissionPercentage"
          size="large"
          icon="%"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/4" v-if="perfilHelpers.isInsurance()">
        <vs-input
          label="Limite de Comissão"
          :disabled="!perfilHelpers.isInsurance()"
          v-model="broker.MaxComissionPercentage"
          size="large"
          icon="%"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/3">
        <vs-input
          label="Situação do CNPJ"
          disabled="true"
          v-model="corretor.CNAESituation"
          size="large"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/3 py-4">
        <vs-input
          label="Código CNAE"
          disabled="true"
          :value="corretor.CNAECode || ((corretor.CNAEs || [])[0] || {}).Code"
          size="large"
        />
      </vs-col>

      <vs-col class="w-full md:w-2/3 py-4">
        <vs-input
          label="Ramo"
          disabled="true"
          :value="
            corretor.CNAEBranch || ((corretor.CNAEs || [])[0] || {}).Description
          "
          size="large"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/3">
        <vs-input
          label="Data do registro"
          disabled="true"
          :value="
            new Date(corretor.CreateDate || corretor.SUSEPRegisterDate)
              | moment('DD/MM/YYYY')
          "
          size="large"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/3">
        <vs-input
          v-if="corretor.RevalidationDate"
          label="Data do recadastro"
          disabled="true"
          :value="new Date(corretor.RevalidationDate) | moment('DD/MM/YYYY')"
          size="large"
        />
        <vs-input
          v-else
          label="Data do recadastro"
          disabled="true"
          value="Sem Registro"
          size="large"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/3">
        <vs-input
          label="Data expiração do cadastro"
          disabled="true"
          :value="corretor.ExpirationDate ? expirationDate : 'Sem registro'"
          size="large"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/3 pt-4">
        <vs-input
          label="Situação SUSEP"
          disabled="true"
          :value="situacaoSusep"
          size="large"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/3 pt-4">
        <vs-input
          label="Codigo SUSEP"
          disabled="true"
          :value="corretor.SUSEPProtocol"
          size="large"
          class="w-full"
        />
      </vs-col>

      <vs-col class="w-full md:w-1/3 pt-4">
        <vs-input
          label="Telefone"
          :disabled="!perfilHelpers.isInsurance() || !podeAlterar"
          v-mask="['(##) ####-####', '(##) #####-####']"
          v-model="broker.PhoneNumber"
          size="large"
        />
      </vs-col>
    </vs-row>

    <form-endereco
      :dadosEndereco="address"
      :disabled="!perfilHelpers.isInsurance() || !podeAlterar"
      @updateValue="updateLocationValue"
    />

    <DadosComplementaresCadastroMapfre
      :permissoesCorretorMapfre="permissoesCorretorMapfre"
      :broker="broker"
      :tiposDeCanais="tiposDeCanais"
    />

    <vs-row v-if="!corretor.IsActive && perfilHelpers.isInsurance()">
      <div class="vx-col pt-5 w-full mt-2">
        <label>Justificativa de bloqueio</label>
        <vs-textarea disabled v-model="corretor.BlockJustify" />
      </div>
    </vs-row>

    <vs-row class="mt-5" vs-justify="flex-end" v-if="podeAlterar">
      <vs-button color="primary" @click="atualizarDados()">
        <span class="font-semibold"> Salvar </span>
      </vs-button>
    </vs-row>
  </div>
</template>

<script>
import { mask, TheMask } from "vue-the-mask";
import axiosInstance from "@/axios";
import FormEndereco from "../enderecos/FormEndereco.vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";

import DadosComplementaresCadastroMapfre from "@/components/dados-complementares-cadastro-mapfre/index.vue";
import mapfreProvider from "@/providers/mapfre-provider.js";
import * as Permissoes from "@/utils/permissions.js";

export default {
  name: "dados-cadastrais",
  props: {
    corretor: {
      type: Object,
      required: true
    }
  },
  directives: { mask },
  components: {
    TheMask,
    FormEndereco,
    DadosComplementaresCadastroMapfre
  },
  data() {
    return {
      valorComissaoAtualizada: 0,
      broker: {},
      perfilHelpers: PerfilHelpers,
      permissoesCorretorMapfre: [],
      tiposDeCanais: []
    };
  },
  async mounted() {
    await this.$onpoint.loading(async () => {
      await this.obterModuloPermissoes(Permissoes.Modulo.CORRETOR);
      await this.pegarTipoCanal();
    });
  },
  created() {
    this.broker = JSON.parse(JSON.stringify(this.corretor));
    if (!this.broker.UniqueId) {
      this.broker.UniqueId = this.$route.params.corretorUniqueId;
    }
  },
  computed: {
    podeAlterar() {
      return this.perfilHelpers.checkPermission([
        this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORES,
        this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORA
      ]);
    },

    brokerName: {
      get() {
        return this.broker.BrokerName || this.corretor.Name;
      },

      set(value) {
        this.broker.BrokerName = value;
      }
    },

    revalidationDate() {
      if (this.corretor.RevalidationDate)
        return (
          new Date(this.corretor.RevalidationDate) |
          this.$options.filters.moment("DD/MM/YYYY")
        );

      return "Sem registro";
    },

    expirationDate() {
      return this.$utils.dateToDDMMYYYY(this.corretor.ExpirationDate);
    },

    situacaoSusep() {
      if (this.corretor.CreateDate)
        return this.corretor.CreateDate | this.$options.filtersstatusSusep;

      return this.corretor.SUSEPStatus || "Sem registro";
    },

    address() {
      return {
        Neighborhood:
          this.broker.Neighborhood || this.broker.LocationNeighborhood,
        Complement:
          this.broker.AddressComplement || this.broker.LocationComplement,
        Number: this.broker.LocationNumber || this.broker.AddressNumber,
        AddressName: this.broker.AddressName || this.broker.LocationStreet,
        ZipCode: this.broker.ZipCode,
        StateProvinceName:
          this.broker.StateProvinceName ||
          this.broker.LocationStateProvinceName,
        StateProvinceId:
          this.broker.StateProvinceId || this.broker.LocationStateProvinceId,
        CityName: this.broker.CityName || this.broker.LocationCityName,
        CityId: this.broker.CityId || this.broker.LocationCityId,
        IsMain: true
      };
    }
  },
  methods: {
    atualizarDados() {
      if (!this.verificarDadosComplementaresCadastro()) return;

      this.$onpoint.loading(() => {
        return axiosInstance
          .put(`api/Broker/UpdateBrokerRegistrationData`, this.broker)
          .then(response => {
            this.$emit("atualizarDadosCadastrais", this.broker);
            this.$onpoint.successModal("Dados alterados com sucesso.");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },

    updateInscricaoMunicipal() {
      this.broker = {
        ...this.broker,
        InscricaoMunicipal: parseInt(this.corretor.InscricaoMunicipal)
      };
    },
    updateLocationValue(location) {
      this.broker = {
        ...this.broker,
        CityId: location.CityId || this.broker.CityId,
        CityName: location.CityName || this.broker.CityName,
        StateProvinceId:
          location.StateProvinceId || this.broker.StateProvinceId,
        StateProvinceName:
          location.StateProvinceName || this.broker.StateProvinceName,
        Neighborhood: location.Neighborhood || this.broker.Neighborhood,
        AddressComplement: location.Complement || this.broker.AddressComplement,
        AddressNumber: location.Number || this.broker.AddressNumber,
        AddressName: location.AddressName || this.broker.AddressName,
        ZipCode: location.ZipCode || this.broker.ZipCode
      };
    },

    async obterModuloPermissoes(modulo) {
      this.permissoesCorretorMapfre = [];
      try {
        this.permissoesCorretorMapfre = await mapfreProvider.obterPermissoesModulo(
          modulo
        );
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    async pegarTipoCanal() {
      this.tiposDeCanais = [];
      let somenteCadastro = false;
      try {
        this.tiposDeCanais = await mapfreProvider.listarTiposCanais(
          somenteCadastro
        );
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    verificarDadosComplementaresCadastro() {
      let codigoCorretorMapfre = this.broker.CodigoCorretor;
      let codigoSucursal = this.broker.CodigoSurcursal;
      let codigoContrato = this.broker.CodigoContrato;
      let canalCorretor = this.broker.TipoCanalId;

      let errosDadosComplementaresCadastro = {
        CodigoCorretor: codigoCorretorMapfre ? "" : "Código do corretor Mapfre",
        CodigoSucursal: codigoSucursal ? "" : "Código da sucursal",
        CodigoContrato: codigoContrato ? "" : "Código do contrato"
      };

      if (canalCorretor) {
        if (canalCorretor === 1) {
          if (!codigoCorretorMapfre || !codigoSucursal || !codigoContrato) {
            this.$onpoint.errorModal([
              "Quando Canal Mapfre escolhido, os campos a seguir devem ser preenchidos:",
              errosDadosComplementaresCadastro.CodigoCorretor,
              errosDadosComplementaresCadastro.CodigoSucursal,
              errosDadosComplementaresCadastro.CodigoContrato
            ]);
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  },
  watch: {
    corretor(value) {
      this.broker = {
        ...value,
        UniqueId: this.$route.params.corretorUniqueId
      };
    }
  }
};
</script>
